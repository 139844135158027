<script setup lang="ts">
import type { ConcreteComponent, ComputedOptions, MethodOptions } from 'vue';
import type { PromoCard } from '~/types/storyblok/promo-card'
import type { trackBlockClickEmit } from '~/types/track-block-click'

const props = defineProps<{
  blok: PromoCard
  noText: boolean
  elementType: string | ConcreteComponent<{}, any, any, ComputedOptions, MethodOptions, {}, any>
}>()
const emit = defineEmits<trackBlockClickEmit>()
</script>

<template>
  <div>
  <component :is="elementType"
      v-editable="blok"
      class="promo-card" 
      :to="$url(blok.link.cached_url)"
      @click="() => emit('trackBlockClick', $url(blok.link.cached_url), 'link')"
    >
      <div v-if="blok.title" class="promo-card__title">
        {{ blok.title }}
      </div>
      <p
        v-if="blok.description"
        class="promo-card__description"
        :class="{ 'hide-on-mobile': !blok.showDescriptionOnMobile }"
      >
        {{ blok.description }}
      </p>
  </component>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/edges';
@import 'assets/scss/rules/breakpoints';

.promo-card {
    background-color: var(--white);
    display: block;
    padding: 2rem 1.6rem 4.8rem 1.6rem;
    @media screen and (min-width: $tablet) {
      padding: 2.8rem 4.8rem 4.8rem 2.4rem;
      min-height: 100%;
    }

    &__title {
      @include subtitle1;
    }

    &__description.hide-on-mobile {
      display: none;
      @media screen and (min-width: $tablet) {
        display: block;
      }
    }
    &:focus-visible {
      outline-offset: -1.6rem;
    }
}

.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.6rem;
}

.wave {
  @include wave;
  margin-top: -1.2rem;
}
</style>
